//google settings are defined in the iife argument at the bottom.
//set your client idscopes and api key there.
//config.immediate should be set to true.
var sfApi = (function () {
	'use strict';

	//sfApi settings are set
	var settings = {
		sr: {},
		schedules: [],
		users: [],
		license: {},
		fields: {},
		apiVersion: '55.0',
		config: {
			client_id: '',
			scope: '',
			immediate: true, //set to true to attempt token retrieval without the pop-up
		},
	}; //end settings argument

	var userTokenMap = {};
	var userProfile;
	var baseURL = _CONFIG.DBK_BASEURL || `//${window.location.host}/`;
	var errorReporter;
	var savedGroupIDForToken;

	var tokenWatcher;

	return {
		clearTokenMemory: clearTokenMemory,
		setErrorReporter: setErrorReporter,
		settings: settings,
		getSecondaryAuth: getSecondaryAuth,
		deleteSecondaryAuth: deleteSecondaryAuth,
		auth: auth,
		deauthorize: deauthorize,
		getUserProfile: getUserProfile,

		// Data and calendar functions
		objects: objects,
		objectInfo: objectInfo,
		findRecords: findRecords,
		getRecord: getRecord,
		createQuery: createQuery,
		createRecord: createRecord,
		updateRecord: updateRecord,
		deleteRecord: deleteRecord,
		publish: publish,
		initialize: initialize,
		isSalesforce: isSalesforce,
		quickFind: quickFind,
		testFields: testFields,
		saveSchedules: saveSchedules,
		getUserInfo: getUserInfo,
		fields: fields,
		getUserPreferences: getUserPreferences,
		isSandbox: isSandbox,
		picklist: picklist,
		testPicklist: testPicklist,
		reloadPage: reloadPage,
		navigate: navigate,
		openURL: openURL,
		testObjects: testObjects,
		objectExists: objectExists,
		recordTypes: recordTypes,
		mapToArray: mapToArray,
		settings: settings,
		users: users,
		systemAdmins: systemAdmins,
		getUserRecordByName: getUserRecordByName,
		resourceObjects: resourceObjects,
		getIdByName: getIdByName,

		// Ajax functions
		ajaxRequest: ajaxRequest,
	};

	//Public Functions******************************************

	function clearTokenMemory() {
		settings.token = null;
		settings.config.immediate = false;
	}

	function setErrorReporter(errorReportingFunction) {
		errorReporter = errorReportingFunction;
	}

	function getUserProfile() {
		return userProfile;
	}

	function isSalesforce() {
		return fbk.isSalesforce();
	}

	function getSecondaryAuth(userID, groupID, sourceID, callback) {
		getToken(userID, sourceID, callback);

		function getToken(userID, sourceID, callback) {
			var params = {
				userID: userID,
				sourceID: sourceID,
				groupIDForToken: groupID,
			};

			ajaxRequest({
				url: baseURL + 'api/salesforce/token',
				type: 'GET',
				params: params,
				onSuccess: function (tokens) {
					if (tokens && tokens.access_token) {
						userReq(callback, tokens);
					} else {
						callback(false);
					}
				},
				onError: function (error) {
					if (callback) {
						callback(false);
					}
				},
			});
		}
	}

	function deleteSecondaryAuth(userID, groupID, sourceID, callback) {
		var params = {
			userID: userID,
			sourceID: sourceID,
			groupIDForToken: groupID,
		};

		ajaxRequest({
			url: baseURL + 'api/salesforce/token',
			type: 'DELETE',
			params: params,
			onSuccess: function (response) {
				callback(true);
			},
			onError: function (error) {
				console.log(error);
				callback(false);
			},
		});
	}

	//Google Authorization routine.
	//Try to get the token silently, if that fails then callback should present button for auth - statusOnly is boolean to avoid an attempt to sign in
	function auth(
		userID,
		sourceID,
		statusOnly,
		forceConsent,
		redirectAuth,
		redirectAuthFunction,
		groupIDForToken,
		secondaryAuth,
		preAuthDialog,
		callback
	) {
		if (!secondaryAuth) {
			savedGroupIDForToken = groupIDForToken;
		}

		// var forceRedirect = (redirectAuth || isStandalone()) && !isSalesforce();
		var forceRedirect = redirectAuth && !isSalesforce();
		var salesforceMobileRedirect = isMobile() && isSalesforce();
		var scope = 'calendar';

		if (statusOnly) {
			settings.config.immediate = true;
		}

		if (forceConsent) {
			settings.config.immediate = false;
		}

		if (!settings.config.immediate && forceRedirect) {
			preAuthDialog(function (authSubDomain) {
				if (!authSubDomain) {
					callback();
					return;
				}
				authRedirect(
					sourceID,
					userID,
					authSubDomain,
					function (result) {
						check(result, callback);
					}
				);
			});
		}
		// else if (!settings.config.immediate && salesforceMobileRedirect) {
		//Enable this if there is no way to tell the difference between the SF mobile app and the SF1 Web page
		// 	salesforceMobileAuthRedirect(sourceID, userID, callBack);
		// }
		else if (!settings.config.immediate) {
			preAuthDialog(function (authSubDomain) {
				if (!authSubDomain) {
					callback();
					return;
				}
				authPopup(sourceID, userID, authSubDomain, function (result) {
					check(result, callback);
				});
			});
		} else if (!settings.token) {
			// Attempt to get new token from server
			updateToken(userID, sourceID, function (result) {
				check(result, callback);
			});
		} else {
			callback(true);
		}

		function authRedirect(sourceID, userID, authSubDomain, callback) {
			var currentURL = window.location.href;
			var redirectURL =
				baseURL +
				'api/salesforce/auth?scope=' +
				scope +
				'&sourceID=' +
				encodeURIComponent(sourceID) +
				'&userID=' +
				encodeURIComponent(userID) +
				'&clientRedirectURL=' +
				encodeURIComponent(currentURL) +
				'&authSubDomain=' +
				encodeURIComponent(authSubDomain);

			if (groupIDForToken) {
				redirectURL += '&groupIDForToken=' + groupIDForToken;
			}

			if (redirectAuthFunction) {
				redirectURL += '&type=redirectFunction';
				redirectAuthFunction(redirectURL, function () {
					// Wrapped in a timeout because sometimes the read from firebase happens before the data is available.
					// Todo figure out a better way than a delay here to ensure the token is ready to be fetched
					window.setTimeout(() => {
						updateToken(userID, sourceID, function (result) {
							check(result, function (result) {
								callback(result);
							});
						});
					}, 500);
				});
			} else {
				redirectURL += '&type=redirect';
				window.location.href = redirectURL;
			}
		}

		function authPopup(sourceID, userID, authSubDomain, callback) {
			var redirectURL =
				baseURL +
				'api/salesforce/auth?type=popup&scope=' +
				scope +
				'&sourceID=' +
				encodeURIComponent(sourceID) +
				'&userID=' +
				encodeURIComponent(userID) +
				'&authSubDomain=' +
				encodeURIComponent(authSubDomain);

			if (groupIDForToken) {
				redirectURL += '&groupIDForToken=' + groupIDForToken;
			}

			var popup = window.open(redirectURL, '', 'width=500, height=690');
			popup.onload = function () {
				// Check for an error loading the url
				var documentTitle = popup.document.title;
				if (documentTitle === '502 Bad Gateway') {
					popup.close();
					errorReporter(502);
				}
			};

			popoverCheck(popup, callback);

			function popoverCheck(popupWindow, callback) {
				window.setTimeout(function () {
					var urlParams = {};
					try {
						urlParams = getURLParams(popupWindow.location.search);
					} catch (error) {
						// Couldn't get url, probably because the popup is currenty showing a google domain
						// Nothing to do here as we will wait for further action either window close or redirect back to our domain
					}
					if (popupWindow && popupWindow.closed) {
						// If window was closed run the callback with no result
						callback();
					} else if (urlParams.access_token) {
						popupWindow.close();

						applyToken(userID, sourceID, urlParams, null);

						callback(urlParams);
					} else {
						popoverCheck(popupWindow, callback);
					}
				}, 250);
			}
		}

		function salesforceMobileAuthRedirect(sourceID, userID, callback) {
			var hidden = false;
			var redirectURL =
				baseURL +
				'api/salesforce/auth?type=popup&scope=' +
				scope +
				'&sourceID=' +
				encodeURIComponent(sourceID) +
				'&userID=' +
				encodeURIComponent(userID) +
				'&forceClose=true';

			if (groupIDForToken) {
				redirectURL += '&groupIDForToken=' + groupIDForToken;
			}

			//Add a listener for popup window closing
			document.addEventListener('visibilitychange', onchange);

			//Open new auth window
			fbk.publish('dbk.navigate', {url: redirectURL, new: true});

			function onchange(e) {
				hidden = !hidden;
				if (!hidden) {
					document.removeEventListener('visibilitychange', onchange);
					auth(userID, sourceID, true, false, callback);
				}
			}
		}
	}

	function updateToken(userID, sourceID, callback) {
		var params = {
			userID: userID,
			sourceID: sourceID,
		};

		if (savedGroupIDForToken) {
			params.groupIDForToken = savedGroupIDForToken;
		}

		ajaxRequest({
			url: baseURL + 'api/salesforce/token',
			type: 'GET',
			params: params,
			onSuccess: function (tokens) {
				applyToken(userID, sourceID, tokens, callback);
			},
			onError: function (error) {
				console.log(error);
				if (callback) {
					callback(false);
				}
			},
		});
	}

	function applyToken(userID, sourceID, tokens, callback) {
		var tokenExpiresMinutes = 60;
		var fetchMinutesBeforeExpires = 5;
		var now = new Date().getTime();

		var token;
		var expires;
		var identityURL;

		var updateTimeout;

		// Clear any pending timeouts
		clearTimeout(tokenWatcher);

		if (tokens && tokens.access_token && !tokens.error) {
			token = tokens.access_token;
			expires = now + 1000 * 60 * tokenExpiresMinutes;
			identityURL = tokens.id;

			updateTimeout =
				expires - now - 1000 * 60 * fetchMinutesBeforeExpires;

			// Remove old token from user token map if it exists
			if (userTokenMap[settings.token]) {
				delete userTokenMap[settings.token];
			}

			// Set the token to global settings
			settings.token = token;
			settings.tokenexpires = now + (expires - 60) * 1000;
			settings.identityURL = identityURL;

			userTokenMap[token] = {
				token: token,
				expires: expires,
				identityURL: identityURL,
				userID: userID,
				sourceID: sourceID,
			};

			settings.config.immediate = true;

			// Set a timeout so we can update the token before it expires
			tokenWatcher = window.setTimeout(function () {
				updateToken(userID, sourceID);
			}, updateTimeout);
		} else {
			// Set to true here as we'll be recalling without re-loading the page
			settings.config.immediate = true;
		}

		if (callback) {
			callback(token);
		}
	}

	// callback for checking our silent try
	function check(result, callback) {
		if (result && !result.error) {
			// we're already authorized in.
			userReq(callback);
		} else {
			//set settings.config.immediate to false so next check brings up the pop-over
			//false callBack should trigger showing the auth button
			settings.config.immediate = false;
			if (callback) {
				callback(false);
			}

			return false;
		}
	}

	// get user info
	function userReq(callback, overrideTokens) {
		var params = {
			access_token: overrideTokens
				? overrideTokens.access_token
				: settings.token,
		};

		ajaxRequest({
			url: overrideTokens ? overrideTokens.id : settings.identityURL,
			type: 'GET',
			params: params,
			onSuccess: function (response) {
				// Mutate user properties to match expected output
				response.accountName = response.username;
				response.accountId = response.user_id;
				response.name = response.display_name;

				if (overrideTokens) {
					callback(response);
				} else {
					initialize(response, callback);
				}
			},
			onError: function (error) {
				console.log(error);
				// Make sure to clear the settings token because there is an error
				settings.token = null;
				if (!error) {
					// Most likely CORS isn't set in Salesforce
					error = {
						errorCode: 400,
						message: 'Could not process request',
					};
					callback(error);
				} else {
					callback(null);
				}
			},
		});
	}

	function deauthorize(userID, sourceID, switchAccount, callback) {
		var params = {
			userID: userID,
			sourceID: sourceID,
		};

		// We need to change this so it passes a useGroupToken value specifically
		// if (useGroupToken) {
		// 	params.useGroupToken = true;
		// }

		if (switchAccount) {
			processDeauthorize(callback);
			return;
		}

		ajaxRequest({
			url: baseURL + 'api/salesforce/token',
			type: 'DELETE',
			params: params,
			onSuccess: function (response) {
				processDeauthorize(callback);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		function processDeauthorize(callback) {
			if (callback) {
				settings.token = null;
				if (!switchAccount) {
					settings.config.immediate = false;
				}
				callback();
			}
		}
	}

	//Public Calendar Functions

	//Load Calendar Lists
	function retryCheck(result) {
		if (!result) {
			return;
		}
		var errorIsArray = result && Array.isArray(result);
		var error = errorIsArray ? result[0] : result;
		var errorResult;
		var reAuth;
		var preventDeauth = true;

		if (error) {
			errorResult = {
				errorIsArray: errorIsArray,
				reAuth: reAuth,
				preventDeauth: preventDeauth,
				errorCode: error.errorCode,
				message: error.message ? error.message : '',
			};
		}

		return errorResult;
	}

	//Private Calendar Functions******************************************
	function ajaxRequest(options, retryCount) {
		// parameter object options:
		//{url, type, params, data, retryCheck, preventErrorReporter, onSuccess, onError}

		var responseResult;
		var paramList = [];
		var data;
		var type;
		var retryCheckResult;
		var url = options.url;
		var code;
		var message;
		var preventDeauth;

		if (!options) {
			return;
		}

		if (!retryCount) {
			retryCount = 0;
		}

		// Build parameter string and append to url
		if (options.params) {
			url += '?';

			for (var param in options.params) {
				paramList.push(
					encodeURIComponent(param) +
						'=' +
						encodeURIComponent(options.params[param])
				);
			}

			url += paramList.join('&');
		}

		type = options.type ? options.type.toUpperCase() : 'GET';

		var xhr = new XMLHttpRequest();
		xhr.open(type, url);
		if (options && options.access_token) {
			xhr.setRequestHeader(
				'Authorization',
				'Bearer ' + options.access_token
			);
		}

		if (options.data) {
			try {
				data = JSON.stringify(options.data);
				xhr.setRequestHeader('Content-Type', 'application/json');
			} catch (error) {
				// Could perform an action if options is not an object
				data = options.data;
				xhr.setRequestHeader(
					'Content-Type',
					'application/x-www-form-urlencoded'
				);
			}
		}

		xhr.onreadystatechange = function (e) {
			if (xhr.readyState == 4) {
				if (xhr.response === '<empty string>') {
					responseResult = '';
				} else {
					try {
						responseResult = JSON.parse(xhr.response);
					} catch (error) {
						responseResult = xhr.response;
					}
				}

				if (
					xhr.status == 200 ||
					xhr.status == 201 ||
					xhr.status == 204
				) {
					if (options.onSuccess) {
						retryCheckResult = options.retryCheck
							? options.retryCheck(responseResult)
							: null;

						if (
							retryCheckResult &&
							retryCount < settings.retryLimit
						) {
							if (retryCheckResult.reAuth) {
								authAndRetry();
							} else {
								retry();
							}

							return;
						}

						options.onSuccess(responseResult);
					}
				} else {
					retryCheckResult = options.retryCheck
						? options.retryCheck(responseResult)
						: null;
					preventDeauth =
						retryCheckResult && retryCheckResult.preventDeauth;

					// Check for an invalid token response and attempt to renew the token. Limit to 3 retries
					if (
						((retryCheckResult && retryCheckResult.reAuth) ||
							(options.params &&
								options.params.access_token &&
								!retryCheckResult &&
								(Number(xhr.status) === 401 ||
									Number(xhr.status) === 403))) &&
						retryCount < settings.retryLimit &&
						userTokenMap[settings.token]
					) {
						authAndRetry();
					} else if (
						retryCheckResult &&
						retryCount < settings.retryLimit
					) {
						retry();
					} else if (options.onError) {
						if (
							(options.preventErrorReporter && !preventDeauth) ||
							!options.preventErrorReporter
						) {
							errorReporter(
								xhr.status,
								retryCheckResult && retryCheckResult.message,
								preventDeauth
							);
						}
						options.onError(
							(retryCheckResult && retryCheckResult.errorIsArray
								? [retryCheckResult]
								: retryCheckResult) || xhr.response,
							xhr.status,
							preventDeauth
						);
					}
				}
			}
		};
		xhr.send(data);

		function authAndRetry() {
			window.setTimeout(function () {
				updateToken(
					userTokenMap[settings.token].userID,
					userTokenMap[settings.token].sourceID,
					function (token) {
						options.params.access_token = token;
						ajaxRequest(options, retryCount + 1);
					}
				);
			}, settings.retryWait);
		}

		function retry() {
			window.setTimeout(function () {
				ajaxRequest(options, retryCount + 1);
			}, settings.retryWait);
		}
	}

	function getURLParams(url) {
		if (!url) {
			return {};
		}
		var match,
			pl = /\+/g, // Regex for replacing addition symbol with a space
			search = /([^&=]+)=?([^&]*)/g,
			decode = function (s) {
				return decodeURIComponent(s.replace(pl, ' '));
			},
			query = url.substring(1);

		var urlParams = {};
		while ((match = search.exec(query))) {
			urlParams[decode(match[1])] = decode(match[2]);
		}
		return urlParams;
	}

	function isStandalone() {
		var standalone =
			'standalone' in window.navigator && window.navigator.standalone;
		return standalone;
	}

	function isIframe() {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}

	function isMobile() {
		return isIos() || isAndroid() || isWindowsPhone();
	}

	function isIos() {
		var userAgent = window.navigator.userAgent.toLowerCase();
		return /iphone|ipod|ipad/.test(userAgent) && !window.MSStream;
	}

	function isAndroid() {
		var userAgent = window.navigator.userAgent.toLowerCase();
		return /android/i.test(userAgent);
	}

	function isWindowsPhone() {
		var userAgent = window.navigator.userAgent.toLowerCase();
		return /windows phone/i.test(userAgent);
	}

	//checks if our token is close to expiration before we attempt a call.
	function tokenOk() {
		//if our token has expired, then re-run the auth with no callBack
		var ts = new Date().getTime();
		if (Number(ts) >= Number(settings.tokenexpires)) {
			return false;
		} else {
			return true;
		}
	}

	function resourceObjects(resourceIdField, source, callback) {
		var objectName = source.objectName;
		fields(objectName, processFields);
		function processFields(data) {
			for (var i = 0; i < data.length; i++) {
				if (data[i].apiName === resourceIdField) {
					var objects = data[i].allReferences;
					callback(objects);
					break;
				}
			}
		}
	}

	function getIdByName(name, object, nameField, callback) {
		if (
			settings.resourceData &&
			settings.resourceData[name] &&
			settings.resourceData[name].object === object
		) {
			//we've already looked up the resource id this session, so pull from settings
			var result = {
				object: settings.resourceData[name].object,
				id: settings.resourceData[name].id,
			};
			callback(result);
			return;
		}
		var select = 'SELECT Id,' + nameField + ' FROM ' + object;
		var where = 'WHERE ' + nameField + "='" + name + "'";
		var params = {
			q: select + ' ' + where,
		};

		ajaxRequest({
			url: settings.restURL + 'query/',
			type: 'GET',
			params: params,
			access_token: settings.token,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				if (response && response.totalSize > 0) {
					processResponse(response);
				} else {
					callback(false);
				}
			},
			onError: function (error) {
				console.log('error', error);
				callback(false);
				// processResponse([
				// 	{
				// 		errorCode: error.errorCode,
				// 		message: error.message,
				// 	},
				// ]);
			},
		});

		function processResponse(data) {
			var id = data.records[0].Id;
			var result = {
				object: object,
				id: id,
			};
			if (!settings.resourceData) {
				settings.resourceData = {};
				settings.resourceData[name] = {
					id: id,
					object: object,
				};
			}
			callback(result);
		}
	}

	function recordTypes(object, callback) {
		// Result for callback needs to be an array
		var select =
			'SELECT Id,DeveloperName FROM RecordType WHERE SobjectType=' +
			"'" +
			object +
			"'" +
			' ORDER BY DeveloperName';

		var params = {
			q: select,
		};

		params.q = select;
		ajaxRequest({
			url: settings.restURL + 'query/',
			type: 'GET',
			params: params,
			access_token: settings.token,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response.records);
			},
			onError: function (error) {
				console.log('error', error);
				processResponse([
					{
						errorCode: error.errorCode,
						message: cleanError(error.message),
					},
				]);
			},
		});

		function processResponse(result) {
			callback(result);
		}
	}

	function objectExists(name, objects) {
		for (var property in objects.sobjects) {
			if (name === objects.sobjects[property].name) {
				return true;
			}
		}
		return false;
	}

	function navigate(id) {
		var url = '/' + id;
		publish('dbk.navigate', {id: id, url: url});
	}

	function openURL(url) {
		publish('dbk.navigate', {url: url, new: true});
	}

	function reloadPage() {
		publish('dbk.navigate', {url: settings.refresh, new: false});
	}

	function isSandbox() {
		var instance = settings.sr.client.instanceUrl;
		if (instance.indexOf('Sandbox') !== -1) {
			return true;
		} else {
			return false;
		}
	}

	//Get the current url parameter string. We can use this to pass properties view the iframe url
	function urlParams() {
		var match,
			pl = /\+/g, // Regex for replacing addition symbol with a space
			search = /([^&=]+)=?([^&]*)/g,
			decode = function (s) {
				return decodeURIComponent(s.replace(pl, ' '));
			},
			query = window.location.search.substring(1),
			params = {};

		while ((match = search.exec(query))) {
			params[decode(match[1])] = decode(match[2]);
		}
		return params;
	}

	//Method of checking if we are currently running inside of salesforce or not

	function isSalesforce() {
		var type = urlParams().type;
		return type === 'salesforce';
	}

	function initialize(userData, callback) {
		settings.userFields = [];

		var taskNotification;
		var eventNotification;

		settings.profileURL =
			userData && userData.urls ? userData.urls.profile : null;
		settings.restURL =
			userData && userData.urls
				? userData.urls.custom_domain +
				  '/services/data/v' +
				  settings.apiVersion +
				  '/'
				: null;
		settings.custom_domain = userData.urls.custom_domain;
		// Set user profile info
		userProfile = userData;
		//helper function

		function findInArray(a, prop, val) {
			var i;
			for (i in a) {
				if (a[i][prop] === val) {
					return a[i];
				}
			}
		}

		fields('User', createResult);

		function createResult(data) {
			//result.accountProfile = prof;
			userProfile.taskNotification = taskNotification;
			userProfile.eventNotification = eventNotification;
			userProfile.accountFirstName = userProfile.first_name;
			userProfile.accountLastName = userProfile.last_name;
			userProfile.accountFullName = userProfile.name;
			userProfile.accountEmail = userProfile.email;
			userProfile.accountTimeZone = userProfile.timezone;
			userProfile.accountLocale = userProfile.locale;

			settings.userInfo = userProfile;

			settings.userNames = {};
			settings.userIds = {};

			settings.userNames[userProfile.accountFullName] = userProfile;
			settings.userIds[userProfile.accountId] = userProfile;

			// result.organizationName = userProfile.name;
			userProfile.organizationId = userProfile.organization_id;
			userProfile.organizationUsers = users;

			//if this user has either notifiction on, then we want to get the lead times.
			if (userProfile.taskNotification || userProfile.eventNotification) {
				reminderDefaults(userProfile.accountId);
			} else {
				callback(userProfile);
			}
		}

		function reminderDefaults(id) {
			userPreference(id, updateDefaults);
			function updateDefaults(data) {
				//we only get a result to this query if the defaults have been entered
				var taskTime = 480;
				var eventTime = 15;
				var i;
				if (data) {
					for (i in data) {
						if (data[i].Preference === '57') {
							eventTime = data[i].Value;
						} else if (data[i].Preference === '58') {
							taskTime = data[i].Value;
						}
					}
				}
				settings.userInfo.eventReminderLeadTime = eventTime;
				settings.userInfo.taskReminderTime = taskTime;
				callback(userProfile);
			}
		}

		//end call backs
		//run our object initialization here
	} // end initialize

	function getUserRecordByName(name, callback) {
		if (settings.userNames[name]) {
			callback(settings.userNames[name]);
		} else {
			users(
				internalCallBack,
				1,
				"Name='" + name.replace(/'/g, "\\'") + "'"
			);
		}
		function internalCallBack(data) {
			if (data[0] && data[0].Id) {
				//cache this result so we only need to query it once per session
				settings.userNames[name] = data[0];
				settings.userIds[data[0].Id] = data[0];
			}
			callback(data);
		}
	}

	function users(usersCallBack, userLimit, where) {
		//let's do the describe first to verify the fields we can query.
		var validFields = [];
		var c = 0;
		var i = 0;
		var thisField;
		var usersResult = [];

		//'foo' included as test, should never be included in query
		var baseFields = [
			'Id',
			'Username',
			'Name',
			'Alias',
			'BannerPhotoUrl',
			'Email',
			'FirstName',
			'LastName',
			'Department',
			'Phone',
			'MobilePhone',
			'Profile.Name',
			'UserPreferencesTaskRemindersCheckboxDefault',
			'UserPreferencesEventRemindersCheckboxDefault',
			'foo',
		];

		function getValidFields(field) {
			var c;
			for (c in settings.userFields) {
				if (
					(field === 'Profile.Name' &&
						settings.userFields[c].apiName === 'ProfileId') ||
					field === settings.userFields[c].apiName
				) {
					return field;
				}
			}
		}

		//need to validate these against those retrieved from fields/Describe call
		for (i in baseFields) {
			thisField = getValidFields(baseFields[i]);
			if (thisField) {
				validFields.push(getValidFields(baseFields[i]));
			}
		}

		var sel = 'SELECT ' + validFields.join(',');
		sel +=
			' FROM User WHERE IsActive=true ' +
			(where ? 'AND ' + where + ' ' : '') +
			'Order By Name Limit ' +
			userLimit;

		var params = {
			q: sel,
		};

		ajaxRequest({
			url: settings.restURL + 'query/',
			type: 'GET',
			params: params,
			access_token: settings.token,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response.records);
			},
			onError: function (error) {
				console.log('error', error);
				processResponse({
					errorCode: error.errorCode,
					message: cleanError(error.message),
				});
			},
		});

		function processResponse(result) {
			callback(result);
		}
	}

	function systemAdmins(callback) {
		//let's do the describe first to verify the fields we can query.
		var validFields = [];
		var c = 0;
		var i = 0;
		var thisField;
		var usersResult = [];
		var isAdmin = false;

		//'foo' included as test, should never be included in query
		var baseFields = [
			'Id',
			'Username',
			'Name',
			'Alias',
			'BannerPhotoUrl',
			'Email',
			'FirstName',
			'LastName',
			'Department',
			'Phone',
			'MobilePhone',
			'Profile.Name',
			'UserPreferencesTaskRemindersCheckboxDefault',
			'UserPreferencesEventRemindersCheckboxDefault',
			'foo',
		];

		function getValidFields(field) {
			var c;
			for (c in settings.userFields) {
				if (
					(field === 'Profile.Name' &&
						settings.userFields[c].apiName === 'ProfileId') ||
					field === settings.userFields[c].apiName
				) {
					return field;
				}
			}
		}

		//need to validate these against those retrieved from fields/Describe call
		for (i in baseFields) {
			thisField = getValidFields(baseFields[i]);
			if (thisField) {
				validFields.push(getValidFields(baseFields[i]));
				if (thisField === 'Profile.Name') {
					isAdmin = true;
				}
			}
		}

		if (!isAdmin) {
			callback({
				status: 200,
				payload: {
					records: [],
				},
			});
		}

		var sel = 'SELECT ' + validFields.join(',');
		sel +=
			" FROM User WHERE IsActive=true AND Profile.Name = 'System Administrator' Order By Name";

		var params = {
			q: sel,
		};

		ajaxRequest({
			url: settings.restURL + 'query/',
			type: 'GET',
			params: params,
			access_token: settings.token,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response.records);
			},
			onError: function (error) {
				console.log('error', error);
				processResponse({
					errorCode: error.errorCode,
					message: cleanError(error.message),
				});
			},
		});

		function processResponse(result) {
			callback(result);
		}
	}

	function getUserPreferences(id, callBack) {
		var i;
		var result = {};
		var user = settings.userIds[id];
		if (!user) {
			user = {};
			settings.userIds[id] = user;
			userData(id, updateDefaults);
			return;
		}
		if (
			!user.UserPreferencesEventRemindersCheckboxDefault &&
			!user.UserPreferencesTaskRemindersCheckboxDefault
		) {
			result.taskRemindersOn = false;
			result.eventRemindersOn = false;
			callBack(result);
			return;
		}
		if (user.eventLeadTime) {
			//we have loaded this account's prefs already, so just load those
			result.taskRemindersOn =
				user.UserPreferencesTaskRemindersCheckboxDefault;
			result.eventRemindersOn =
				user.UserPreferencesEventRemindersCheckboxDefault;
			result.eventLeadTime = user.eventLeadTime;
			result.taskLeadTime = user.taskLeadTime;
			callBack(result);
			return;
		} else {
			//we need to query salesforce for this
			userPreference(id, updateDefaults);
		}

		function updateDefaults(data) {
			if (data.status === 0) {
				result = {
					errorCode:
						'No response from Salesforce. Check Internet Connection.',
					message:
						'No response from Salesforce. Check Internet Connection.',
				};
				callBack(result);
				return;
			} else {
				data = data.records;
			}
			//we only get a result to this query if the defaults have been entered
			var taskTime = 480;
			var eventTime = 15;
			var i;
			if (data) {
				for (i in data) {
					if (data[i].Preference === '57') {
						eventTime = data[i].Value;
					} else if (data[i].Preference === '58') {
						taskTime = data[i].Value;
					}
				}
			}
			user.eventLeadTime = eventTime;
			user.taskLeadTime = taskTime;
			result.taskRemindersOn =
				user.UserPreferencesTaskRemindersCheckboxDefault;
			result.eventRemindersOn =
				user.UserPreferencesEventRemindersCheckboxDefault;
			result.eventLeadTime = user.eventLeadTime;
			result.taskLeadTime = user.taskLeadTime;
			callBack(result);
		}
		function userPreference(id, callback) {
			var sel = 'SELECT Preference,Value';
			sel += " FROM UserPreference WHERE UserId = '" + id + "'";
			var params = {
				q: sel,
			};

			ajaxRequest({
				url: settings.restURL + 'query/',
				type: 'GET',
				params: params,
				access_token: settings.token,
				retryCheck: retryCheck,
				onSuccess: function (response) {
					callback(response);
				},
				onError: function (error) {
					console.log('error', error);
				},
			});
		}

		function userData(userId, callback) {
			var sr = settings.sr;
			var sel =
				'SELECT UserPreferencesTaskRemindersCheckboxDefault,UserPreferencesEventRemindersCheckboxDefault';
			sel += " FROM User WHERE Id = '" + userId + "'";

			var params = {
				q: sel,
			};

			ajaxRequest({
				url: settings.restURL + 'query/',
				type: 'GET',
				params: params,
				access_token: settings.token,
				retryCheck: retryCheck,
				onSuccess: function (response) {
					processResult(response);
				},
				onError: function (error) {
					console.log('error', error);
				},
			});

			function processResult(result) {
				if (result.totalSize > 0) {
					settings.userIds[
						userId
					].UserPreferencesTaskRemindersCheckboxDefault =
						result.records[0].UserPreferencesTaskRemindersCheckboxDefault;
					settings.userIds[
						userId
					].UserPreferencesEventRemindersCheckboxDefault =
						result.records[0].UserPreferencesEventRemindersCheckboxDefault;
				}
				userPreference(userId, callback);
			}
		}
	}

	function testUserPreference() {
		userPreference(null, callBack);
		function callBack(d) {
			console.log(JSON.stringify(d, null, 2));
		}
	}

	//function for retrieving all available objects

	function objects(callback) {
		ajaxRequest({
			url: settings.restURL + 'sobjects/',
			type: 'GET',
			// params: params,
			access_token: settings.token,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response);
			},
			onError: function (error) {
				console.log('error', error);
				processResponse({
					errorCode: error.errorCode,
					message: cleanError(error.message),
				});
			},
		});

		function processResponse(result) {
			callback(result);
		}
	}

	function testObjects() {
		function callBack(d) {
			console.log(d);
		}
		objects(callBack);
	}

	//function for retrieving metadata and fields for the specified object

	function objectInfo(objectName, callback) {
		if (!objectName) {
			return;
		}
		ajaxRequest({
			url:
				settings.restURL +
				'sobjects/' +
				objectName +
				'/describe/' +
				'?nocache=' +
				new Date().getTime(),
			type: 'GET',
			// params: params,
			access_token: settings.token,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response);
			},
			onError: function (error) {
				console.log('error', error);
				if (!error) {
					// Return empty array if no error code is given
					processResponse([]);
					return;
				}
				processResponse({
					errorCode: error[0].errorCode,
					message: cleanError(error[0].message),
				});
			},
		});

		function processResponse(result) {
			callback(result);
		}
	}

	function testObjectInfo() {
		function callback(d) {
			var i;
			var result = [];
			for (i in d) {
				result.push({
					apiName: d[i].name,
					label: d[i].name,
					type: d[i].type,
					updateable: d[i].updateable,
				});
			}
		}
		objectInfo('Account', callback);
	}

	//field info by object name

	function fields(object, callback, typeArray) {
		objectInfo(object, filter);

		function filter(d) {
			if (d.errorCode) {
				callback([d]);
				return;
			}
			var i;
			var result = [];
			for (i in d.fields) {
				if (!typeArray || inArray(typeArray, d.fields[i].type)) {
					result.push({
						apiName: d.fields[i].name,
						label: d.fields[i].label,
						type: d.fields[i].type,
						nameField: d.fields[i].nameField,
						updateable: d.fields[i].updateable,
						picklistValues: d.fields[i].picklistValues,
						restrictedPicklist: d.fields[i].restrictedPicklist,
						defaultValue: d.fields[i].defaultValue,
						defaultValueFormula: d.fields[i].defaultValueFormula,
						relationshipName: d.fields[i].relationshipName,
						allReferences: d.fields[i].referenceTo,
						referenceTo: d.fields[i].referenceTo[0]
							? d.fields[i].referenceTo[0]
							: '',
						length: d.fields[i].length,
					});
				}
			}
			settings.fields[object] = result;
			if (callback) {
				callback(result);
			}
		}
	}

	function testFields(object) {
		function callBack(d) {
			console.log(JSON.stringify(d, null, 2));
		}
		fields(object, callBack);
	}

	function picklist(object, field, callBack) {
		fields(object, processResult);
		function processResult(data) {
			if (data[0] && data[0].errorCode) {
				callBack(data);
				return;
			}
			var i;
			var result = false;
			for (i in data) {
				if (data[i].apiName === field) {
					if (data[i].picklistValues.length > 0) {
						result = {};
						result.picklist = data[i].picklistValues;
						result.restricted = data[i].restrictedPicklist;
					}
				}
			}
			callBack(result);
			return;
		}
	}

	function testPicklist() {
		picklist('Task', 'Status', callBack);
		function callBack(data) {
			console.log(data);
		}
	}

	//function for doing a quick find in the specified fields

	function quickFind(
		objectName,
		searchField,
		displayField,
		criteria,
		callback,
		firstWordOnly
	) {
		var sel = 'SELECT Id,' + displayField + ' FROM ' + objectName;
		var q;

		//encode single quotes so they don't break the SOQL
		criteria = criteria.replace(/'/g, '\\' + "'");

		//if Name is the field for lead or contact then optimize for first and last name
		if (
			(objectName === 'Contact' || objectName === 'Lead') &&
			searchField === 'Name'
		) {
			q =
				' WHERE FirstName LIKE ' +
				"'" +
				criteria +
				'%' +
				"'" +
				' OR LastName LIKE ' +
				"'" +
				criteria +
				'%' +
				"'" +
				' OR Name LIKE ' +
				"'" +
				criteria +
				'%' +
				"'" +
				' ORDER BY ' +
				displayField;
		} else if (firstWordOnly) {
			q =
				' WHERE ' +
				searchField +
				' LIKE ' +
				"'" +
				criteria +
				'%' +
				"'" +
				' OR ' +
				searchField +
				' LIKE ' +
				"'the " +
				criteria +
				'%' +
				"'" +
				' OR ' +
				searchField +
				' LIKE ' +
				"'an " +
				criteria +
				'%' +
				"'" +
				' OR ' +
				searchField +
				' LIKE ' +
				"'a " +
				criteria +
				'%' +
				"'" +
				' ORDER BY ' +
				displayField;
		} else {
			q =
				' WHERE ' +
				searchField +
				' LIKE ' +
				"'" +
				criteria +
				'%' +
				"'" +
				' OR ' +
				searchField +
				' LIKE ' +
				"'% " +
				criteria +
				'%' +
				"'" +
				' ORDER BY ' +
				displayField;
		}

		var params = {
			q: sel + q,
		};

		ajaxRequest({
			url: settings.restURL + 'query/',
			type: 'GET',
			params: params,
			access_token: settings.token,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				getResults(response);
			},
			onError: function (error) {
				console.log('error', error);
			},
		});

		function getResults(d) {
			var records = [];
			processResult(d);

			function processResult(d) {
				var result = d.records;

				for (var i = 0; i < result.length; i++) {
					records.push(result[i]);
					if (i > 500) {
						break;
					}
				}

				callback(records);
			}
		}
	}

	function getRecord(objectName, id, callback) {
		ajaxRequest({
			url: settings.restURL + 'sobjects/' + objectName + '/' + id,
			type: 'GET',
			// params: params,
			access_token: settings.token,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response);
			},
			onError: function (error) {
				console.log('error', error);
			},
		});

		function processResponse(result) {
			callback(result);
		}
	}

	//function for retrieving array of records from the specified object
	//fields returned for the passed object are specified in the schedule.fieldmap
	//queries use the "request" syntax where an array of objects are passed and each object represents an OR clause containg 1-n AND clauses
	//queroes can be constructed using fbk property names specified in the settings.mappingOut object in the IFFE argument

	function findRecords(
		objectName,
		callback,
		requests,
		scheduleId,
		additionalWhere
	) {
		if (!settings.fields[objectName] && additionalWhere) {
			fields(objectName, function () {
				findRecords(
					objectName,
					callback,
					requests,
					scheduleId,
					additionalWhere
				);
			});
			return;
		}

		var q;
		//transform requests to WHERE clause
		if (requests) {
			q = createQuery(requests, objectName, scheduleId);
		} else {
			q = '';
		}

		var sel = createSelect();

		if (additionalWhere) {
			q += additionalWhere;
		}

		var params = {
			q: sel + q,
		};
		ajaxRequest({
			url: settings.restURL + 'query/',
			type: 'GET',
			params: params,
			preventErrorReporter: true,
			access_token: settings.token,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				getResults(response);
			},
			onError: function (error) {
				console.log('error', error);
				callback([
					{
						errorCode: error[0].errorCode,
						message: cleanError(error[0].message),
					},
				]);
			},
		});

		function getResults(d) {
			var records = [];
			processResult(d);
			function processResult(d) {
				var result = d.records ? d.records : d;

				//Add the result to the records array
				for (var i = 0; i < result.length; i++) {
					records.push(result[i]);
				}
				if (d.done === false) {
					//additional results initiate next call
					ajaxRequest({
						url: settings.custom_domain + d.nextRecordsUrl,
						type: 'GET',
						// params: params,
						access_token: settings.token,
						retryCheck: retryCheck,
						onSuccess: function (response) {
							processResult(response);
						},
						onError: function (error) {
							console.log('error', error);
							callback({
								errorCode: error.errorCode,
								message: cleanError(error.message),
							});
						},
					});
				} else {
					//We are done paging results so send the returned records to our callback
					callback(records);
				}
			}
		}

		function createSelect() {
			//get our field mapping for this object
			var fieldMap;
			var schedule;
			var result = '';
			for (var i in settings.schedules) {
				if (settings.schedules[i].id === scheduleId) {
					fieldMap = settings.schedules[i].fieldMap;
					schedule = settings.schedules[i];
				}
			}
			var props = mapToArray(schedule);
			for (var c in props) {
				if (result.length === 0) {
					result += 'SELECT ' + props[c];
				} else {
					result += ',' + props[c];
				}
			}
			return result + ' FROM ' + objectName + ' ';
		}
	}

	//helper function to convert a field map to a de-duped array of fields
	function mapToArray(schedule) {
		var fieldMap = schedule.fieldMap;
		var c;
		var i;
		var thisProp;
		var props = [];
		var newProp;
		var titleProperties = [];

		if (!schedule.unusedMap) {
			schedule.unusedMap = {};
		}

		for (c in fieldMap) {
			if (fieldMap[c] && !schedule.unusedMap[c]) {
				if (
					!fieldMap[c].allowedSchedules ||
					fieldMap[c].allowedSchedules.schedule.objectName
				) {
					thisProp = fieldMap[c].split(',');
					for (i in thisProp) {
						props.push(thisProp[i].trim());
					}
				}
			}
		}

		//strip out tags
		var firstSplit;
		var secondSplit;
		var thirdSplit;
		var splitProps = [];
		for (i = 0; i < props.length; i++) {
			firstSplit = props[i].split('>');
			if (firstSplit.length > 1) {
				var fieldArray = [];
				for (var ii = 0; ii < firstSplit.length; ii++) {
					secondSplit = firstSplit[ii].split('</');
					if (secondSplit.length > 1 && secondSplit[0]) {
						splitProps.push(secondSplit[0]);
					}
				}
			} else {
				splitProps.push(props[i]);
			}
		}
		var used = [];
		var result = [];
		for (c in splitProps) {
			newProp = splitProps[c];
			//don't add to query if already added or if designated as unused
			if (used.indexOf(newProp) === -1) {
				result.push(newProp);
				used.push(newProp);
			}
		}
		//sort this by descending length, so we don't sub out any substrings
		return result.sort(compareLength);

		function compareLength(a, b) {
			return b.length - a.length;
		}
	}

	//helper function for findRecords().
	//Creates a formatted SOQL WHERE clause from an array of objects.
	//Public function for testing and demonstration.

	function createQuery(requests, objectName, scheduleId) {
		var fieldMap;
		var schedule;
		var i = 0;
		for (i in settings.schedules) {
			if (settings.schedules[i].id === scheduleId) {
				fieldMap = settings.schedules[i].fieldMap;
				schedule = settings.schedules[i];
				break;
			}
		}

		function createOrClause(o) {
			var oProps = Object.getOwnPropertyNames(o);
			var c = 0;
			var result = '';
			var line = '';
			var sourceProp = '';
			var sfProp = '';
			var value = '';
			for (c in oProps) {
				sourceProp = oProps[c];
				value = o[sourceProp];
				var rangeSplit = value.split('...');
				if (rangeSplit.length === 2) {
					line =
						fieldMap[sourceProp] +
						' > ' +
						rangeSplit[0] +
						' AND ' +
						fieldMap[sourceProp] +
						' < ' +
						rangeSplit[1];
					if (schedule.recordType) {
						line +=
							" AND ( RecordTypeId='" +
							schedule.recordType +
							"' )";
					}
				} else {
					//if we're trying to define end, and it's not mapped, then set it to start.
					if (sourceProp === 'end' && !fieldMap[sourceProp]) {
						sourceProp = 'start';
					}
					//translate to SF properties
					if (fieldMap[sourceProp]) {
						sourceProp = fieldMap[sourceProp];
					}
					line = sourceProp + ' ' + value;
					if (schedule.recordType) {
						line +=
							" AND ( RecordTypeId='" +
							schedule.recordType +
							"' )";
					}
				}
				if (c > 0) {
					result += ' AND ' + line;
				} else {
					result += ' ( ' + line;
				}
			}
			return result + ' ) ';
		}

		//initialize as array if single object passed.
		if (typeof requests[0] !== 'object' && typeof request === 'object') {
			requests = [requests];
		}
		var line = '';
		var result = '';
		i = 0;
		for (i in requests) {
			line = createOrClause(requests[i]);
			if (i > 0) {
				result += ' OR ' + line;
			} else {
				result = ' WHERE ( ' + line;
			}
		}

		//constrain to record types if specified
		return result + ' ) ';
	}

	//function for creating a new record in the specified object. On success, it returns the newly created object

	function createRecord(objectName, callback, request, scheduleId, viewEnd) {
		ajaxRequest({
			url: settings.restURL + 'sobjects/' + objectName + '/',
			type: 'POST',
			data: request,
			// params: params,
			access_token: settings.token,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response);
			},
			onError: function (error) {
				console.log('error', error);
				callback([
					{
						errorCode: error[0].errorCode,
						message: cleanError(error[0].message),
					},
				]);
			},
		});

		//internal callBack for constructing return object.
		function processResponse(result) {
			var requests = [];
			if (result.success === true) {
				//we're a repating event, so go back for the instances for this view
				if (request.IsRecurrence) {
					if (objectName === 'Event') {
						requests.push({
							RecurrenceActivityId: '=' + "'" + result.id + "'",
							StartDateTime: '<=' + viewEnd.format(),
						});
					} else if (objectName === 'Task') {
						requests.push({
							RecurrenceActivityId: '=' + "'" + result.id + "'",
							ActivityDate: '<=' + viewEnd.format('YYYY-MM-DD'),
						});
					}
				} else {
					requests.push({id: '=' + "'" + result.id + "'"});
				}
				findRecords(objectName, callback, requests, scheduleId);
			} else {
				callback(result);
			}
		}
	}

	//function for updating a record in the specified object. On success, it returns the updated object

	function updateRecord(objectName, recordId, callback, request, scheduleId) {
		ajaxRequest({
			url:
				settings.restURL +
				'sobjects/' +
				objectName +
				'/' +
				recordId +
				'/',
			type: 'PATCH',
			data: request,
			// params: params,
			access_token: settings.token,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response.records);
			},
			onError: function (error) {
				console.log('error', error);
				callback([
					{
						errorCode: error[0].errorCode,
						message: cleanError(error[0].message),
					},
				]);
			},
		});

		//internal callBack for constructing return object.
		function processResponse(result) {
			if (!result) {
				//no errors find and transfor object
				var requests = [
					{
						id: '=' + "'" + recordId + "'",
					},
				];
				findRecords(objectName, callback, requests, scheduleId);
				return;
			}
			callback(result);
		}
	}

	//function for deleting a record in the specified object. On success, it returns {success:true}

	function deleteRecord(objectName, recordId, callback) {
		ajaxRequest({
			url:
				settings.restURL +
				'sobjects/' +
				objectName +
				'/' +
				recordId +
				'/',
			type: 'DELETE',
			// params: params,
			access_token: settings.token,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response);
			},
			onError: function (error) {
				console.log('error', error);
				callback([
					{
						errorCode: error[0].errorCode,
						message: cleanError(error[0].message),
					},
				]);
			},
		});

		//internal callBack for constructing return object.

		function processResponse(result) {
			if (!result) {
				result = [
					{
						success: true,
					},
				];
				//no errors
			}
			callback(result);
		}
	}

	//function for getting the userId by name

	function getUserByName(name) {
		var i = 0;
		for (i in settings.users) {
			if (settings.users[i].Name === name) {
				return settings.users[i];
			}
		}
		return '';
	}

	function getUserById(id) {
		var i = 0;
		for (i in settings.users) {
			if (settings.users[i].Id === id) {
				return settings.users[i];
			}
		}
		return '';
	}

	//in array helper
	function inArray(array, val, prop) {
		var i;
		for (i in array) {
			if (array[i] == val) {
				return true;
			}
		}
		return false;
	}

	//general publish function for publishing events to visualforce page

	function publish(event, payload) {
		Sfdc.canvas.client.publish(settings.sr.client, {
			name: event,
			payload: payload,
		});
	}

	function saveSchedules(schedules) {
		settings.schedules = schedules;
	}

	function getUserInfo(property) {
		if (!property) {
			return;
		}
		return settings.userInfo[property];
	}

	//end public functions

	function cleanError(str) {
		if (!str) {
			return str;
		}
		return str.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	}

	//end all functions
})();
